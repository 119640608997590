import * as React from "react";

type Props = { title: string; url: string };
export const SinglePublication = ({ title, url }: Props) => {
  return (
    <div className={"p-2"}>
      <a href={url}>
        <div className="flex flex-col rounded p-3 bg-gray-50 hover:bg-gray-200">
          <b>{title}</b>
          <span className={"text-xs"}>{url}</span>
        </div>
      </a>
    </div>
  );
};
