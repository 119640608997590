import Layout from "../components/layout";
import Seo from "../components/seo";
import * as React from "react";
import Bio from "../components/bio";
import { graphql } from "gatsby";
import { SinglePublication } from "../components/single-publication";

export const Publications = () => {
  return (
    <Layout>
      <Bio />
      <Seo title={"Veröffentlichungen von Lars Kölpin-Freese"} />
      <div>
        <SinglePublication
          title={"Slides: Modern Web: Trends der Webentwicklung"}
          url={
            "https://www.slideshare.net/_openknowledge/modern-web-trends-der-webentwicklung"
          }
        />
        <SinglePublication
          title={"Slides: Mehr Sicherheit durch Automatisierung (gitops)"}
          url={
            "https://www.slideshare.net/_openknowledge/mehr-sicherheit-durch-automatisierung"
          }
        />
        <SinglePublication
          title={
            "Systeme für Continuous Integration und Continuous Delivery im Vergleich"
          }
          url={"https://www.heise.de/select/ix/2021/12/2107510285116693424"}
        />
        <SinglePublication
          title={
            "Automatische Architektur-Checks für TypeScript-Projekte mit FeatureLint"
          }
          url={
            "https://entwickler.de/software-architektur/eslint-tools-archunit"
          }
        />
        <SinglePublication
          title={"Replizierte Datenhaltung mit dem RAFT-Algorithmus"}
          url={"https://entwickler.de/testing/datenhaltung-raft-algorithmus"}
        />
        <SinglePublication
          title={"Wie Cloud-Computing ein Umdenken der Entwickler einfordert"}
          url={
            "https://entwickler.de/java/wie-cloud-computing-ein-umdenken-der-entwickler-einfordert-001"
          }
        />
        <SinglePublication
          title={"Wie Cloud-Computing ein Umdenken der Entwickler einfordert"}
          url={
            "https://entwickler.de/kubernetes/automatische-softwareupdates-renovate"
          }
        />
        <SinglePublication
          title={"Zwei Systeme synchron halten"}
          url={"https://entwickler.de/azure/software-systeme-synchron-halten"}
        />
        <SinglePublication
          title={"„Das kann ich dir noch nicht beantworten“"}
          url={"https://entwickler.de/api/techniken-asynchrone-web-apis"}
        />
      </div>
    </Layout>
  );
};

export default Publications;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
